/* global _ moment */
import { formPermission } from 'app/modules/main/form/services/FormPermissions'
import { blacklistNPTColumns } from 'app/helper'
import confetti from 'canvas-confetti'
const operatorsMap = require('app/modules/main/workflow/session/operatorsMap')
const UUID = require('uuid')
const debug = require('debug')('nextplus:form-class')
const jsonLogic = require('root/../common/services/json-logic-service.js')
const emptyValuesNullTypes = ['upload', 'button', 'input', 'textarea']
module.exports = class CustomFormClass {
  constructor (
    form,
    fields,
    certificateIds,
    roles,
    sessionId,
    unitId = null,
    formDataId = null,
    model = {},
    sessionData = {},
    parentLinkedForm = {},
    extraKeys = []
  ) {
    this.form = form
    this.fields = fields
    this.fieldsById = _.keyBy(fields, 'id')
    this.sessionId = sessionId
    this.unitId = unitId
    this.certificateIds = certificateIds
    this.roles = roles
    this.formDataId = formDataId
    this.model = model
    this.sessionData = sessionData
    this.parentLinkedForm = parentLinkedForm
    this.sessionDataKeys = [
      'sessionNodeId',
      'nodeId',
      'nodeName',
      'workflowId',
      'sessionId',
      // 'workflowName',
      // 'workflowVersion',
      // 'workflowSubVersion',
      // 'workflowNormalizedVersion',
      // 'serial',
      'workorderId',
      // 'workorderNumber',
      // 'recordId',
      'originalWorkflowId',
      'originalNodeId',
      'partSku',
      'partRev',
      'openInPreview',
      // 'orderNumber',
      // 'accountName',
      'stockId',
      ...extraKeys
    ]
    this.variableNames = [
      'today',
      'status',
      'created',
      'closedAt',
      'modified',
      'submittedForApproval',
      'approved',
      'rejected',
      'stockSku',
      'stockSerial',
      'stockLot',
      'partSku',
      'serial',
      'workorderNumber'
    ]
    this.init(form)
  }

  init (form) {
    debug('init')
    const that = this
    if (!form.type || form.type !== 'WorkorderStatus') {
      const {
        closeCertificate,
        singleton,
        hasStatus,
        unassigned,
        removeAssignee,
        statuses,
        approvalWorkflows,
        defaultStatus,
        defaultAssignee,
        context,
        fieldSettings,
        presaveTriggers,
        postsaveTriggers
      } = form
      this.closeCertificate = closeCertificate
      this.userFieldsPermissions = that.certificateIds
        .map(certificateId => `certificate_${certificateId}`)
        .concat(that.roles.map(role => `role_${role.id}`))
      this.createPermissions = formPermission(
        'create',
        form,
        that.certificateIds,
        that.roles.map(r => r.id)
      )
      this.closePermissions = formPermission(
        'close',
        form,
        that.certificateIds,
        that.roles.map(r => r.id)
      )
      this.singleton = singleton || false
      this.defaultAssignee = defaultAssignee
      this.defaultStatus = defaultStatus
      this.approvalWorkflows = approvalWorkflows || []
      this.fieldSettings = fieldSettings || {}
      this.presaveTriggers = presaveTriggers || []
      this.postsaveTriggers = postsaveTriggers || []
      this.model.displayFieldsObject = {}
      if (this.formDataId) {
        // edit
        this.isNewInstance = false
        this.model.stockSerialOrLot =
          this.model.stockSerial || this.model.stockLot
        this.originalModel = _.cloneDeep(this.model)
        this.formFields = this.originalModel.fields
        this.formWithStatus = this.model.hasStatus
        this.statuses = this.model.hasStatus
          ? this.model.statuses
          : statuses || []
        this.unAssigned = this.model.unassigned
        this.removeAssignee = this.model.removeAssignee
        this.context = this.model.context || context
        this.viewers = this.model.viewers || []
        this.hasResolvedWorkflowVersion =
          this.context === 'workflow' &&
          !_.isNil(this.model.resolvedWorkflowVersion)
        if (this.model.approvalWorkflow) {
          this.model.submittedForApproval = !!(
            this.model.approvalWorkflow.status === 'PENDING' ||
            this.model.approvalWorkflow.status === 'APPROVED' ||
            this.model.approvalWorkflow.status === 'REJECTED'
          )
          this.model.approved =
            this.model.approvalWorkflow.status === 'APPROVED'
          this.model.rejected =
            this.model.approvalWorkflow.status === 'REJECTED'
        }
        if (this.model.isDraft && this.sessionData?.partSku) {
          this.model.stockSku = this.sessionData.partSku
          this.model.stockSerialOrLot = this.sessionData.isSerial
            ? this.sessionData.serial
            : this.sessionData.workorderNumber
        }
      } else {
        // new
        this.isNewInstance = true
        this.formDataId = UUID()
        this.formFields = _.cloneDeep(form.fields)

        this.formWithStatus = hasStatus
        this.statuses = statuses || []
        this.unAssigned = unassigned
        this.removeAssignee = removeAssignee
        this.context = context || 'session'
        if (this.parentLinkedForm && this.form.requireDeviceLink) {
          if (this.parentLinkedForm.stockSku) {
            this.model.stockSku = this.parentLinkedForm.stockSku
            this.model.stockSerialOrLot =
              this.parentLinkedForm.stockSerial ||
              this.parentLinkedForm.stockLot ||
              null
          }
        }
      }
      this.statusByName = _.keyBy(this.statuses, 'name')
      if (this.formFields?.length > 0) {
        this.formFieldsById = _.keyBy(this.formFields, 'id')
        this.formFields.forEach(field => {
          const dbField = this.fieldsById[field.fieldId]
          this.model.displayFieldsObject[field.id] = {
            hide: false,
            options: dbField?.options || []
          }
        })
      }
    } else {
      this.formFields = _.cloneDeep(this.model.fields)
      this.createPermissions = true
    }
  }

  getFormDataId () {
    return this.formDataId
  }

  setModel (newModel) {
    this.model = newModel
    this.init(this.form)
  }

  manipulateModel (userData) {
    if (this.isNewInstance) {
      // new
      this.model.ownerId = userData.id
      this.model.UserId = userData.id
      this.model.linkedTo = []
      this.model.linkedToForms = []
      if (this.formWithStatus) {
        this.model.status = this.defaultStatus
      }
      if (this.removeAssignee) {
        this.model.assignee = []
      } else {
        const assignee = []
        if (!this.unAssigned) {
          assignee.push(userData.id)
        }
        this.model.assignee =
          this.defaultAssignee && this.defaultAssignee.length > 0
            ? this.manipulateAssignee(userData, this.defaultAssignee)
            : assignee
      }
      if (this.parentLinkedForm?.fields) {
        // hard coded field copy for nova
        // find field on parent form
        // const field = this.parentLinkedForm.fields.find(
        //   f => f.fieldId === '889c3ae0-ea99-11ee-b845-c3750b422e38'
        // )
        // if (field) {
        //   // find field on current form
        //   const formField = this.formFields.find(
        //     f => f.fieldId === '889c3ae0-ea99-11ee-b845-c3750b422e38'
        //   )
        //   if (formField) {
        //     // copy value from parent form to current form
        //     this.model[formField.id] = field.value
        //   }
        // }
      }
    } else {
      // edit
      const fields = this.formFields.filter(
        field => !field.id.includes('_lookup_')
      )
      fields.forEach(field => {
        if (!_.isNil(field.value)) {
          this.model[field.id] = field.value
        }
      })
      this.model.hasLogs = this.model
        ? this.model.changeLog && this.model.changeLog.length > 0
        : false
    }
    return this.model
  }

  manipulateAssignee (userData, assignee = []) {
    const validConstants = ['manager', 'owner']
    return _.compact(
      assignee.map(userId => {
        if (validConstants.includes(userId)) {
          if (userId === 'manager' && userData.manager) {
            return userData.manager
          } else if (userId === 'owner') {
            return userData.id
          }
          return null
        }
        return userId
      })
    )
  }

  isAlreadyResolved () {
    return this.hasResolvedWorkflowVersion
  }

  getSessionDataKeys () {
    return this.sessionDataKeys
  }

  getFormFields () {
    return this.formFields
  }

  isEditMode () {
    return this.isNewInstance === false
  }

  isUnassignedForm () {
    return this.unAssigned
  }

  shouldRemoveAssignee () {
    return this.removeAssignee
  }

  isSingleton () {
    return this.singleton
  }

  getCloseCertificate () {
    return this.closeCertificate
  }

  getSessionDetails () {
    return (this.model && this.model?.sessionObject) || {}
  }

  getWorkorderId () {
    return this.model?.workorderId || null
  }

  getWorkorderName () {
    return this.model?.workorderNumber || null
  }

  getWorkorderOrderNumber () {
    return this.model?.orderNumber || null
  }

  getWorkorderCustomerAccountName () {
    return this.model?.accountName || null
  }

  getContext () {
    return this.context
  }

  getViewers () {
    return this.viewers
  }

  canCreate () {
    return this.createPermissions
  }

  canClose () {
    return this.closePermissions
  }

  hasStatus () {
    return this.formWithStatus
  }

  getStatuses () {
    return this.statuses
  }

  hasApprovalWorkflows () {
    return this.approvalWorkflows && this.approvalWorkflows.length > 0
  }

  getApprovalWorkflow () {
    if (this.model.approvalWorkflow && this.model.approvalWorkflow.id) {
      return this.approvalWorkflows.find(
        approval => approval.id === this.model.approvalWorkflow.id
      )
    }
    return null
  }

  shouldFillAssignee () {
    return !this.removeAssignee && !this.unAssigned
  }

  valueIsField (name) {
    return Array.isArray(name)
      ? false
      : /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
          name
        )
  }

  canEditField (fieldId) {
    if (
      this.fieldSettings &&
      this.fieldSettings[fieldId] &&
      this.fieldSettings[fieldId].permissions &&
      this.fieldSettings[fieldId].permissions.length
    ) {
      const permissions = this.fieldSettings[fieldId].permissions.filter(perm =>
        this.userFieldsPermissions.find(uPerm => uPerm === perm)
      )
      if (permissions.length === 0) return false
    }
    return true
  }

  convertModelToFormDataObject (model, isShowExpression = false) {
    const formData = _.cloneDeep(model)
    const fields = []
    const allFormFields = this.getFormFields()
    const formFields = allFormFields.filter(
      field => !field.id.includes('_lookup_')
    )
    const lookupFields = allFormFields.filter(field =>
      field.id.includes('_lookup_')
    )
    for (let f = 0; f < formFields.length; f++) {
      const formField = formFields[f]
      const fieldObject = this.fieldsById[formField.fieldId]
      let value = !_.isUndefined(formData[formField.id])
        ? formData[formField.id]
        : null
      if (value === '' && emptyValuesNullTypes.includes(fieldObject.type)) {
        value = null
      }
      const formFieldObject = {
        id: formField.id,
        fieldId: formField.fieldId,
        value
      }
      if (
        this.fieldSettings &&
        this.fieldSettings[formField.id] &&
        this.fieldSettings[formField.id].showInSession
      ) {
        formFieldObject.showInSession = true
      }
      fields.push(formFieldObject)
      if (fieldObject && fieldObject.type === 'lookupSelect') {
        if (formData[`${formField.id}_lookup`]) {
          Object.keys(formData[`${formField.id}_lookup`]).forEach(
            innerFieldId => {
              if (!blacklistNPTColumns.includes(innerFieldId)) {
                fields.push({
                  id: `${formField.id}_lookup_${innerFieldId}`,
                  fieldId: formField.fieldId,
                  value:
                    formData[`${formField.id}_lookup`][innerFieldId] || null
                })
              }
            }
          )
        } else if (
          lookupFields.some(field =>
            field.id.includes(`${formField.id}_lookup_`)
          )
        ) {
          const fieldExtraLookupFields = lookupFields.filter(field =>
            field.id.includes(`${formField.id}_lookup_`)
          )
          fields.push(...fieldExtraLookupFields)
        }
      }
    }
    const isEmpty = fields.every(
      field =>
        field.value === null ||
        (Array.isArray(field.value) && field.value.length === 0)
    )
    if (isEmpty && !isShowExpression) return null
    const formDataObject = {
      id: this.formDataId,
      formId: this.form.id,
      ownerId: formData.ownerId,
      isDraft: formData.isDraft,
      // context: this.context,
      sessionId:
        this.sessionId === '-1' || this.sessionId === 'constant'
          ? null
          : this.sessionId,
      // hasStatus: this.hasStatus(),
      // statuses: this.getStatuses(),
      removeAssignee: this.shouldRemoveAssignee(),
      unassigned: this.isUnassignedForm(),
      status: this.formWithStatus ? formData.status : null,
      assignee: formData.assignee || [],
      fields,
      changeLog: formData.changeLog || [],
      stockSku: formData.stockSku || null,
      stockSerial: formData.stockSerial || null,
      stockLot: formData.stockLot || null,
      linkedTo: formData.linkedTo || [],
      linkedFrom: formData.linkedFrom || []
    }
    if (formData.stockSerialOrLot) {
      if (formData.isPartSerial) {
        formDataObject.stockSerial = formData.stockSerialOrLot
      } else {
        formDataObject.stockLot = formData.stockSerialOrLot
      }
    }
    delete formData.isPartSerial
    delete formData.stockSerial
    if (this.isNewInstance) {
      if (this.parentLinkedForm) {
        // add parentLinkedForm data only in new form-data
        this.sessionDataKeys.forEach(key => {
          formDataObject[key] = !_.isNil(this.parentLinkedForm[key])
            ? this.parentLinkedForm[key]
            : null
        })
      } else if (!_.isEmpty(this.sessionData)) {
        // add session data only in new form-data
        this.sessionDataKeys.forEach(key => {
          formDataObject[key] = !_.isNil(this.sessionData[key])
            ? this.sessionData[key]
            : null
        })
      }
    }

    if (
      this.context === 'workflow' &&
      formData.showInWorkflow &&
      !_.isNil(formData.resolvedWorkflowVersion)
    ) {
      formDataObject.resolvedWorkflowVersion = formData.resolvedWorkflowVersion
    }
    return formDataObject
  }

  checkFormDataValidation (model, formIsValid = false) {
    let isValid = true
    const errorMessages = []
    const formDataObject = this.convertModelToFormDataObject(model)
    if (!formDataObject) {
      // empty form
      isValid = false
      errorMessages.push({ message: 'FORM.ERROR.FORM_IS_EMPTY', params: {} })
    } else {
      const formFields = this.getFormFields()
      if (!formIsValid) {
        const relevantFields = this.fields.filter(field =>
          formFields.find(f => f.fieldId === field.id)
        )
        const requiredFields = relevantFields.filter(field => field.required)
        const requiredFieldsById = _.keyBy(requiredFields, 'id')
        const formFieldsRequired = formFields.filter(f => {
          const requiredFieldObject = requiredFieldsById[f.fieldId]
          if (requiredFieldObject) {
            if (requiredFieldObject.type === 'checkbox') {
              return !model[f.id]
            }
            return _.isNil(model[f.id])
          }
          return false
        })
        isValid = false
        if (formFieldsRequired.length === 0) {
          // error in sub-form
          errorMessages.push({
            message: 'FORM.ERROR.REQUIRED_FIELDS_IN_SUB_FORM',
            params: {}
          })
        } else {
          // error in form field
          errorMessages.push({
            message: 'FORM.ERROR.REQUIRED_FIELDS_FORM',
            params: {
              fieldNames: formFieldsRequired
                .map(f => requiredFieldsById[f.fieldId].title)
                .join()
            }
          })
        }
      }
      const subFormFields = this.fields.filter(
        field =>
          field.type === 'subForm' &&
          !_.isNil(field.minRequiredRows) &&
          field.minRequiredRows > 0
      )
      if (subFormFields && subFormFields.length) {
        for (let i = 0; i < subFormFields.length; i++) {
          const { id, title, minRequiredRows } = subFormFields[i]
          const formField = formFields.find(f => f.fieldId === id)
          if (
            _.isNil(model[formField.id]) ||
            !_.isArray(model[formField.id]) ||
            model[formField.id].length < minRequiredRows
          ) {
            isValid = false
            errorMessages.push({
              message: 'FORM.ERROR.SUB_FORM_MIN_ROWS_REQUIRED',
              params: { fieldName: title, number: minRequiredRows }
            })
          }
        }
      }
      if (
        this.shouldFillAssignee() &&
        (_.isNil(formDataObject.assignee) ||
          formDataObject.assignee.length === 0)
      ) {
        isValid = false
        errorMessages.push({
          message: 'FORM.ERROR.ASSIGNEE_IS_REQUIRED_FORM',
          params: {}
        })
      }
    }
    return { isValid, errorMessages }
  }

  collectErrorMessages (obj) {
    this.errorMessages.push(obj)
  }

  checkFormObjectValidation (formObject, inlineMode) {
    const errors = Object.keys(formObject.$error)
    for (let i = 0; i < errors.length; i++) {
      const errorType = errors[i]
      const innerFormObject = formObject.$error[errorType]
      if (innerFormObject === true) {
        let fieldKey = formObject.$$attr.testid
        if (inlineMode) {
          fieldKey = fieldKey.replace('temp_', '')
        }
        let formFieldObject = this.formFields.find(f => f.id === fieldKey)
        let fieldObject = {}
        const fieldTitle = []
        if (!formFieldObject) {
          const parentFormElement = formObject.$$parentForm.$$element[0]
          const parentKey = parentFormElement.getAttribute('sub-form-id')
          const parentIndex = parentFormElement.getAttribute('sub-form-index')
          if (!isNaN(parseInt(parentIndex))) {
            fieldTitle.push(parseInt(parentIndex) + 1)
          }
          formFieldObject = this.formFields.find(f => f.id === parentKey)
          if (this.fieldsById[fieldKey]) {
            fieldTitle.push(this.fieldsById[fieldKey].title)
          }
        }
        if (formFieldObject) {
          fieldObject = this.fieldsById[formFieldObject.fieldId]
          fieldTitle.unshift(fieldObject.title)
        }
        let errorMsg = ''
        if (errorType === 'required') {
          errorMsg = 'FORM.ERROR.REQUIRED_FIELDS_FORM'
        } else if (errorType === 'parserError') {
          errorMsg = 'FORM.ERROR.PARSE_ERROR_FORM'
        } else if (errorType === 'pattern') {
          errorMsg = 'FORM.ERROR.PATTERN_FIELDS_FORM'
        } else if (errorType === 'min') {
          errorMsg = 'COULD_NOT_SIGN_WITH_MIN_ERROR'
        } else if (errorType === 'max') {
          errorMsg = 'COULD_NOT_SIGN_WITH_MAX_ERROR'
        } else {
          errorMsg = 'FORM_VALIDATION_ERROR'
        }
        this.collectErrorMessages({
          message: errorMsg,
          errorType,
          fieldName: fieldTitle.join(' -> '),
          params: {}
        })
      } else if (innerFormObject.length) {
        for (let j = 0; j < innerFormObject.length; j++) {
          this.checkFormObjectValidation(innerFormObject[j], inlineMode)
        }
      }
    }
  }

  checkValidation (model, forms = [], inlineMode = false) {
    this.errorMessages = []
    for (let f = 0; f < forms.length; f++) {
      const formObject = forms[f]
      this.checkFormObjectValidation(formObject, inlineMode)
    }
    const formDataObject = this.convertModelToFormDataObject(model)
    if (this.errorMessages.length === 0 && !formDataObject) {
      // EMPTY FORM
      this.collectErrorMessages({
        message: 'FORM.ERROR.FORM_IS_EMPTY',
        params: {}
      })
    } else {
      const formFields = this.getFormFields()
      const subFormFields = this.fields.filter(
        field =>
          field.type === 'subForm' &&
          formFields.find(f => f.fieldId === field.id) &&
          !_.isNil(field.minRequiredRows) &&
          field.minRequiredRows > 0
      )
      if (subFormFields && subFormFields.length > 0) {
        for (let i = 0; i < subFormFields.length; i++) {
          const { id, title, minRequiredRows } = subFormFields[i]
          const formField = formFields.find(f => f.fieldId === id)
          if (
            !Array.isArray(model[formField.id]) ||
            model[formField.id].length < minRequiredRows
          ) {
            this.collectErrorMessages({
              message: 'FORM.ERROR.SUB_FORM_MIN_ROWS_REQUIRED',
              params: { fieldName: title, number: minRequiredRows }
            })
          }
        }
      }
      if (
        this.shouldFillAssignee() &&
        formDataObject &&
        (_.isNil(formDataObject.assignee) ||
          formDataObject.assignee.length === 0)
      ) {
        this.collectErrorMessages({
          message: 'FORM.ERROR.ASSIGNEE_IS_REQUIRED_FORM',
          params: {}
        })
      }
    }
    return {
      isValid: this.errorMessages.length === 0,
      errorMessages: this.errorMessages
    }
  }

  getDataForJsonLogic (formDataObject) {
    const data = {}
    this.variableNames.forEach(variableName => {
      if (variableName === 'today') {
        data[variableName] = moment(new Date()).toISOString()
        const dateWithoutTime = new Date()
        dateWithoutTime.setHours(0, 0, 0, 0)
        data.today_no_time = moment(dateWithoutTime).toISOString()
      } else {
        let variableValue = formDataObject[variableName] || null
        if (typeof variableValue === 'boolean') {
          variableValue = variableValue === true
        }
        data[variableName] = variableValue
      }
    })
    formDataObject.fields.forEach(field => {
      let value = null
      const fieldObject = this.fieldsById[field.fieldId]
      if (fieldObject) {
        switch (fieldObject.type) {
          case 'checkbox':
            value = !!(field.value === 'true' || field.value === true)
            break
          case 'datePicker':
          case 'dateTimePicker':
            value = field.value ? moment(field.value).toISOString() : null
            break
          default:
            value = field.value
        }
      } else {
        value =
          typeof field.value === 'boolean'
            ? !!(field.value === 'true' || field.value === true)
            : field.value && field.value instanceof Date
            ? moment(field.value).toISOString()
            : field.value
      }
      data[field.id] = value
    })
    return data
  }

  checkConditions (conditions, formDataObject) {
    const data = this.getDataForJsonLogic(formDataObject)

    data.oldFormData = this.getDataForJsonLogic(
      this.isNewInstance ? { fields: [] } : this.originalModel
    )
    data.isNewInstance = this.isNewInstance
    return jsonLogic.apply(conditions, data)
  }

  isDateField (uniqueId) {
    const fieldObject = this.formFields.find(f => f.id === uniqueId)
    if (!fieldObject) return false
    const field = this.fieldsById[fieldObject.fieldId]
    return field && field.type === 'datePicker'
  }

  checkTriggerConditions (conditions, formDataObject) {
    let actions = []
    for (let i = 0; i < conditions.length; i++) {
      const condition = conditions[i]
      let type = 'and'
      if (condition.type === 'OR' || condition.type === 'or') {
        type = 'or'
      }
      const jsonLogicObject = {
        [type]: []
      }
      for (let j = 0; j < condition.values.length; j++) {
        const conditionValue = condition.values[j]
        const isBoolean =
          typeof conditionValue.value === 'boolean' ||
          conditionValue.type === 'boolean'
        let value = isBoolean
          ? !!(conditionValue.value === 'true' || conditionValue.value === true)
          : conditionValue.value
        if (this.valueIsField(value) || this.variableNames.includes(value)) {
          if (
            value === 'today' &&
            this.valueIsField(conditionValue.field) &&
            this.isDateField(conditionValue.field)
          ) {
            value = { var: 'today_no_time' }
          } else {
            value = { var: value }
          }
        } else if (Array.isArray(value)) {
          value = value.map(val =>
            this.valueIsField(val) || this.variableNames.includes(value)
              ? { var: val }
              : val
          )
        }
        if (
          conditionValue.field === 'today' &&
          this.valueIsField(conditionValue.value) &&
          this.isDateField(conditionValue.value)
        ) {
          jsonLogicObject[type].push({
            [operatorsMap[conditionValue.operator]]: [
              { var: 'today_no_time' },
              value
            ]
          })
        } else {
          jsonLogicObject[type].push({
            [operatorsMap[conditionValue.operator]]: [
              { var: conditionValue.field },
              value
            ]
          })
        }
      }
      if (
        jsonLogicObject[type].length === 0 ||
        this.checkConditions(jsonLogicObject, formDataObject)
      ) {
        actions = actions.concat(condition.actions)
      }
    }
    return actions
  }

  createJsonLogicObject (conditions) {
    if (conditions.condition === 'OR' || conditions.condition === 'or') {
      conditions.condition = 'or'
    } else {
      conditions.condition = 'and'
    }
    const jsonLogicObject = {
      [conditions.condition]: []
    }
    conditions.rules.forEach(rule => {
      if (rule.condition) {
        jsonLogicObject[conditions.condition].push(
          this.createJsonLogicObject(rule)
        )
      } else {
        const isBoolean =
          typeof rule.value === 'boolean' || rule.type === 'boolean'
        let value = isBoolean
          ? !!(rule.value === 'true' || rule.value === true)
          : rule.value

        if (this.valueIsField(value) || this.variableNames.includes(value)) {
          if (
            value === 'today' &&
            this.valueIsField(rule.field) &&
            this.isDateField(rule.field)
          ) {
            value = { var: 'today_no_time' }
          } else {
            value = { var: value }
          }
        } else if (Array.isArray(value)) {
          value = value.map(val =>
            this.valueIsField(val) || this.variableNames.includes(value)
              ? { var: val }
              : val
          )
        }
        if (
          rule.field === 'today' &&
          this.valueIsField(rule.value) &&
          this.isDateField(rule.value)
        ) {
          jsonLogicObject[conditions.condition].push({
            [operatorsMap[rule.operator]]: [{ var: 'today_no_time' }, value]
          })
        } else {
          jsonLogicObject[conditions.condition].push({
            [operatorsMap[rule.operator]]: [{ var: rule.field }, value]
          })
        }
      }
    })
    return jsonLogicObject
  }

  checkFieldDisplayExpression (fieldSettings, field, formDataObject) {
    const that = this
    let hide = false
    const options = []
    const optionDisplayObject = {}
    if (fieldSettings) {
      const { displayLogic, optionsDisplayLogic } = fieldSettings
      if (displayLogic) {
        const { rule, conditions } = displayLogic
        if (rule === 'show') {
          hide = false
        } else if (rule === 'hide') {
          hide = true
        } else {
          const conditionPass = that.checkConditions(
            that.createJsonLogicObject(conditions),
            formDataObject
          )
          if (
            (rule === 'showWhen' && conditionPass) ||
            (rule === 'hideWhen' && !conditionPass)
          ) {
            hide = false
          } else if (
            (rule === 'showWhen' && !conditionPass) ||
            (rule === 'hideWhen' && conditionPass)
          ) {
            hide = true
          }
        }
      }
      if (optionsDisplayLogic) {
        optionsDisplayLogic.forEach(expression => {
          const { rule, conditions, options } = expression
          if (rule === 'show') {
            options.forEach(option => {
              optionDisplayObject[option] = true
            })
          } else if (rule === 'hide') {
            options.forEach(option => {
              if (!optionDisplayObject[option]) {
                optionDisplayObject[option] = false
              }
            })
          } else {
            const conditionPass = that.checkConditions(
              that.createJsonLogicObject(conditions),
              formDataObject
            )
            if (
              (rule === 'showWhen' && conditionPass) ||
              (rule === 'hideWhen' && !conditionPass)
            ) {
              options.forEach(option => {
                optionDisplayObject[option] = true
              })
            } else if (
              (rule === 'showWhen' && !conditionPass) ||
              (rule === 'hideWhen' && conditionPass)
            ) {
              options.forEach(option => {
                if (!optionDisplayObject[option]) {
                  optionDisplayObject[option] = false
                }
              })
            }
          }
        })
      }
    }
    field.options.forEach(option => {
      const value = option.id ? option.id : option.value
      if (
        typeof optionDisplayObject[value] === 'undefined' ||
        optionDisplayObject[value] === true
      ) {
        options.push(option)
      }
    })
    return {
      hide,
      options
    }
  }

  createDisplayFieldsObject = function createDisplayFieldsObject (
    userData = null,
    tempModel = {}
  ) {
    const that = this
    const formDataObject = userData
      ? {
          ...that.manipulateModel(userData),
          ...tempModel
        }
      : that.convertModelToFormDataObject(that.model, true)
    Object.keys(that.form.fieldSettings).forEach(key => {
      const formField = that.formFieldsById[key]
      if (formField) {
        const dbField = that.fieldsById[formField.fieldId]
        if (dbField) {
          const { hide, options } = that.checkFieldDisplayExpression(
            that.form.fieldSettings[key],
            dbField,
            formDataObject
          )
          that.model.displayFieldsObject[key].hide = hide
          if (hide && that.model[key]) {
            if (
              dbField.type === 'subForm' ||
              dbField.type === 'imageBasedForm'
            ) {
              that.model[key] = []
            }
            that.model[key] = null
          }
          that.model.displayFieldsObject[key].options = options
          if (dbField.type === 'select' || dbField.type === 'radio') {
            const manipulatedOptions = options.map(option =>
              option.id ? option.id : option.value
            )
            if (
              that.model?.[key] &&
              !manipulatedOptions.includes(that.model[key])
            ) {
              that.model[key] = null
            }
          }
        }
      }
    })
  }

  executeTrigger (triggerObject, formDataObject, isNew, submitForApproval) {
    const that = this
    return new Promise(async resolve => {
      const { principleType, conditions } = triggerObject
      const shouldRunInNewForm =
        principleType === 'create' || principleType === 'createOrEdit'
      const shouldRunInEditForm =
        principleType === 'edit' || principleType === 'createOrEdit'
      const shouldRunInSubmitForApproval = principleType === 'submitForApproval'
      if (
        (isNew && shouldRunInNewForm) ||
        (!isNew && shouldRunInEditForm) ||
        (shouldRunInSubmitForApproval && submitForApproval)
      ) {
        debug('Check trigger', triggerObject)
        const actions = that.checkTriggerConditions(conditions, formDataObject)
        resolve(actions)
      } else {
        resolve(null)
      }
    })
  }

  executeTriggers (
    formData,
    type = 'presave',
    isNew = false,
    submitForApproval = false
  ) {
    const that = this
    return new Promise(async resolve => {
      const res = []
      let triggers = []
      switch (type) {
        case 'presave':
          triggers = that.presaveTriggers
          break
        case 'postsave':
          triggers = that.postsaveTriggers
          break
      }
      for (let t = 0; t < triggers.length; t++) {
        const trigger = triggers[t]
        const actions = await that.executeTrigger(
          trigger,
          formData,
          isNew,
          submitForApproval
        )
        if (actions && actions.length) {
          res.push(...actions)
        }
      }
      if (type === 'postsave') {
        if (isNew || formData.status !== this.originalModel.status) {
          if (
            this.statusByName[formData.status] &&
            this.statusByName[formData.status].status === 'close'
          ) {
            const fireConfetti = function fireConfetti (particleRatio, opts) {
              confetti({
                resize: true,
                origin: { y: 0.5 },
                particleCount: Math.floor(200 * particleRatio),
                ...opts
              })
            }
            const fireFinishConfetti = function fireFinishConfetti () {
              fireConfetti(0.25, {
                spread: 26,
                startVelocity: 55
              })
              fireConfetti(0.2, {
                spread: 60
              })
              fireConfetti(0.35, {
                spread: 100,
                decay: 0.91,
                scalar: 0.8
              })
              fireConfetti(0.1, {
                spread: 120,
                startVelocity: 25,
                decay: 0.92,
                scalar: 1.2
              })
              fireConfetti(0.1, {
                spread: 120,
                startVelocity: 45
              })
            }
            fireFinishConfetti()
          }
        }
      }
      resolve(res)
    })
  }

  getVariablesForCondition () {
    const res = {}
    for (let i = 0; i < this.variableNames.length; i++) {
      const variableName = this.variableNames[i]
      if (variableName === 'today') {
        // SKIP
      } else if (['created', 'modified'].includes(variableName)) {
        res[variableName] = this.model[variableName] || new Date()
      } else {
        res[variableName] = this.model[variableName]
      }
    }
    return res
  }
}
